<template>
  <div class="center-horizontally">
    <v-card-text class="signInX">
      <!-- <div class="signInX" @click="redirect()">X</div> -->
    </v-card-text>

    <!-- <v-card-text class="signInTitle">Hello, Welcome to LimboBust</v-card-text> -->
    <v-card-text class="signInTitle mt-n4">Enter OTP below</v-card-text>

    <v-alert dense v-if="alertMessage.status !== ''" :type="alertMessage.status === 'success' ? 'success' : 'error'"
      @click="dismissAlert">
      <span class="pass-text">{{ alertMessage.message }}</span>
    </v-alert>
    <v-card-text class="login-form">
      <v-form ref="loginForm" v-model="isValid">
        <!--User name-->
        <v-card-text class="sub-label mt-n6"><span class="asterisk">*</span>OTP Code</v-card-text>

        <v-text-field ref="otp" density="compact" :rules="rules.required" v-model="formData.otp"
          class="mx-2 mt-n2 custom-placeholder no-underline" placeholder="Enter otp" solo bg-color="white"
          variant="outlined"></v-text-field>
      </v-form>
    </v-card-text>

    <v-card-actions class="mt-0">
      <v-spacer />
      <v-btn size="large" class="test-login-btn" @click="Forgot()"><span class="login--text" v-if="!loginState">Verify
          Otp</span>
        <v-progress-circular v-if="loginState" indeterminate color="purple"></v-progress-circular>
      </v-btn>
      <v-spacer />
    </v-card-actions>

    <v-card-actions class="mt-4">
      <v-spacer />
      <v-btn :to="{ name: 'signUp' }" class="capitalize-first-letter text-decoration-underline forgot-text mt-n5">
        Create New Account</v-btn>
      <v-spacer />
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: "testPage",

  data() {
    return {
      isValid: false,
      loginState: false,
      screenSize: "",
      isOtpValid: false,
      formData: {
        otp: "",
        phone: "",
      },
      otpformData: {
        otp: "",
      },
      showPassword: false,
      rules: {
        required: [
          (value) => !!value || "Required.",
          (value) => /^[0-9]*$/.test(value) || "Only numbers are allowed.",
        ],
      },
    };
  },

  computed: {
    alertMessage() {
      return this.$store.getters["auth/authGetters"]("otpAlert");
    },
    otpMessage() {
      return this.$store.getters["auth/authGetters"]("otpAlert");
    },
  },

  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.alertMessage.status = "";
    this.alertMessage.message = "";
  },
  methods: {
    Forgot() {
      if (this.formData.otp === "") {
        this.alertMessage.status = "error";
        this.alertMessage.message = "all fields required";
      } else {
        const Phone = atob(this.$route.params.code);
        this.formData.phone = String(Phone);
        this.loginState = true;

        this.$store.dispatch("auth/verifyOtp", this.formData);
      }
    },
    checkScreenSize() {
      const isSmallScreen = window.matchMedia("(max-width: 768px)").matches;
      this.screenSize = isSmallScreen ? "Small" : "Large";
    },

    verify: function () {
      if (!this.isOtpValid) {
        this.$refs.otpForm.validate();
      } else {
        if (this.otpformData.otp.length === 4) {
          this.$store.dispatch("Auth/verifyOtp", this.otpformData);
        }
      }
    },
    Resend: function () {
      if (!this.isOtpValid) {
        this.$refs.otpForm.validate();
      } else {
        if (this.otpformData.otp.length === 4) {
          this.$store.dispatch("Auth/verifyOtp", this.otpformData);
        }
      }
    },
    dismissAlert() {
      // Clear the alert
      this.alertMessage.status = "";
      this.alertMessage.message = "";
    },
  },

  watch: {
    alertMessage: {
      handler: function () {
        if (this.alertMessage.code !== 1000) {
          this.loginState = false;
        } else {
          this.dialog = true;
        }
      },
    },
    otpMessage: {
      handler: function () {
        if (this.otpMessage.code === 1000) {
          this.$router.push({
            name: "resetPassword",
            params: {
              code: btoa(this.formData.phone),
            },
          });
        }
      },
    },
  },
};
</script>

<style>
@import url("../style.css");
</style>
