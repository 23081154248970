import call from "@/service/http";
import AuthConstants from "./authConstants";
import AuthService from "../auth/views/authService";

const authModule = {
  namespaced: true,
  state: {
    userD: {},
    alert: {
      status: "",
      message: "",
      code: 0,
    },
    otpAlert: {
      status: "",
      message: "",
      code: 0,
    },
    resetAlert: {
      status: "",
      message: "",
      code: 0,
    },
    forgotAlert: {
      status: "",
      message: "",
      code: 0,
    },
  },
  mutations: {
    SET_ALERT: (state, payload) => {
      state.alert = payload || {
        status: "",
        message: "",
        code: 0,
      };
    },
    SET_OTP_ALERT: (state, payload) => {
      state.otpAlert = payload || {
        status: "",
        message: "",
        code: 0,
      };
    },
    SET_RESET_ALERT: (state, payload) => {
      state.resetAlert = payload || {
        status: "",
        message: "",
        code: 0,
      };
    },
    SET_FORGOT_ALERT: (state, payload) => {
      state.forgotAlert = payload || {
        status: "",
        message: "",
        code: 0,
      };
    },
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
  },
  getters: {
    // alert: (state) => state.alert,
    authGetters: (state) => (val) => state[val],
  },
  actions: {
    login: ({ commit }, data) => {
      call("post", AuthConstants.login, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            // console.log(es.data.user);
            AuthService.login(res.data.token, res.data.user);
            commit("SET_ALERT", {
              status: "success",
              message: res.data.status_desc,
            });
            // this.$router.replace({ name: 'LandingPage' });
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
        })
        .catch((err) => {
          commit("SET_ALERT", {
            status: "error",
            message: "Login Failed",
          });
          console.log(err);
        });
    },
    forgot: ({ commit }, data) => {
      call("post", AuthConstants.forgot, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("SET_FORGOT_ALERT", {
              status: "success",
              message: res.data.status_desc,
              code: res.data.status_code,
            });
          } else {
            commit("SET_FORGOT_ALERT", {
              status: "error",
              message: res.data.status_desc,
              code: res.data.status_code,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    verifyOtp: ({ commit }, data) => {
      call("post", AuthConstants.verify, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("SET_OTP_ALERT", {
              status: "success",
              message: res.data.status_desc,
              code: res.data.status_code,
            });
          } else {
            commit("SET_OTP_ALERT", {
              status: "error",
              message: res.data.status_desc,
              code: res.data.status_code,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetPassword: ({ commit }, data) => {
      call("post", AuthConstants.reset, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            commit("SET_RESET_ALERT", {
              status: "success",
              message: res.data.status_desc.concat(" Please Login Below "),
              code: res.data.status_code,
            });
          } else {
            commit("SET_RESET_ALERT", {
              status: "error",
              message: res.data.status_desc,
              code: res.data.status_code,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getUser: ({ commit }, data) => {
      call("post", AuthConstants.user, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            // console.log(res.data.status_code);
            commit("MUTATE", { state: "userD", value: res.data.user });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    register: ({ dispatch, commit }, data) => {
      // console.log(commit);
      call("post", AuthConstants.register, data)
        .then((res) => {
          if (res.data.status_code === 1000) {
            dispatch("login", data);
          } else {
            commit("SET_ALERT", {
              status: "error",
              message: res.data.status_desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default authModule;
